<template>
	<PopupTwoCol name="caserne"
	text="<h3>Caserne (garage sécurité)</h3><br/><p>60 pompiers professionnels se relayent jour et nuit sur le site de la raffinerie afin d’assurer la sécurité de tous. Ils sont équipés d’une vingtaine de camions, dont l’un des plus gros camions à grande capacité hydraulique en Europe, et du matériel nécessaire en cas d’incendie ou autre problème majeur. Ils sont capables d’intervenir en quelques minutes. Un partenariat permet aux pompiers du SDIS 44 d’utiliser les infrastructures du site pour leurs formations.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/CASERNE_f72bpd_c_scale_w_350.jpg 350w,
		img/popup/CASERNE_f72bpd_c_scale_w_591.jpg 591w,
		img/popup/CASERNE_f72bpd_c_scale_w_765.jpg 765w"
		data-src="img/popup/CASERNE_f72bpd_c_scale_w_765.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/Garage_securite_bubtra_c_scale_w_350.jpg 350w,
		img/popup/Garage_securite_bubtra_c_scale_w_774.jpg 774w,
		img/popup/Garage_securite_bubtra_c_scale_w_970.jpg 970w"
		data-src="img/popup/Garage_securite_bubtra_c_scale_w_970.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
